// internal
import App from '@/page/app.vue';
import router from '@/ts/router';
import { createHead } from '@unhead/vue';
import { createApp } from 'vue';
import { createI18n } from "vue-i18n";
import pinia from './store/pinia';

import { CopyStore } from '@/ts/store/copy';
import { DictStore } from '@/ts/store/dict';
import { ProfileStore } from '@/ts/store/profile';
import { SettingsStore } from '@/ts/store/settings';

// code
import hljsVuePlugin from "@highlightjs/vue-plugin";
import hljs from 'highlight.js/lib/core';
import bash from 'highlight.js/lib/languages/bash'; //html
import json from 'highlight.js/lib/languages/json'; //html
import xml from 'highlight.js/lib/languages/xml'; //html
import 'highlight.js/styles/atom-one-dark.css';
hljs.registerLanguage('xml', xml);
hljs.registerLanguage('json', json);
hljs.registerLanguage('bash', bash);

// directives
import confetti from "canvas-confetti";
import ColorDirective from "./directives/color";
import DragOverDirective from "./directives/dragover";
import DropDirective from "./directives/drop";
import UploadDirective from "./directives/upload";

// plugins
// import JsonEditorVue from 'json-editor-vue';
// import CodeEditor from 'simple-code-editor';
import { humanize } from 'humanize';
import vue3GoogleLogin from 'vue3-google-login';
  
// api
import api from '@/ts/api';

// tools
import tools from '@/ts/tools.js';

// header
import Download from '@/page/download.vue';
import Footer from '@/page/footer.vue';
import Head from '@/page/head.vue';
import Hooks from '@/page/hooks.vue';
import EmbedForm from '@/page/html.vue';
import Login from '@/page/login.vue';
import Onboarding from '@/page/onbording.vue';
import Preview from '@/page/preview.vue';

// profile
import Profile from '@/page/profile/profile.vue';

// admin
import AdminAccounts from '@/page/admin/accounts.vue';

// local
import BlockTitle from '@/ui/local/blockTitle.vue';
import CopyCardSmall from '@/ui/local/copyCardSmall.vue';

//general
import Box from '@/ui/box.vue';
import Gap from '@/ui/gap.vue';

//local kit
import Checkbox from '@/ui/kit/checkbox.vue';
import Input from '@/ui/kit/input.vue';
import Switch from '@/ui/kit/switch.vue';
import Text from '@/ui/kit/text.vue';

// admin
import AdminCopyCard from '@/ui/local/adminCopyCardSmall.vue';

// ui
import Ago from '@/ui/ago.vue';
import Color from '@/ui/color.vue';
import ColorPreview from '@/ui/colorPreview.vue';
import Dark from '@/ui/dark.vue';
import Down from '@/ui/down.vue';
import Drop from '@/ui/drop.vue';
import Error from '@/ui/error.vue';
import Lang from '@/ui/lang.vue';
import Popup from '@/ui/popup.vue';

// loader
import Loader from '@/ui/loaders/circles.vue';


// bout
// import Card from '@/ui/card.vue';
// import Progress from '@/ui/progress.vue';
// import User from '@/ui/user.vue';

// import translations
import en from "./locales/en.json";
// import ru from "./locales/ru.json";


//block editor
// import Header from '@editorjs/header';
// import List from '@editorjs/list';



// (window as any).log = function(v: any){
//     if (process.env.NODE_ENV != 'development') return
//     console.log('APP:',v)
// }

// const log = function(v: any){
//     if (process.env.NODE_ENV != 'development') return
//     console.log('APP:',v)
// }


// app
const app = createApp(App)

// router
app.use(router);
router.isReady().then(() => {app.mount('#app')});

// pinia
app.use(pinia)

// stores
app.config.globalProperties.db = {
    settings: SettingsStore(),
    profile: ProfileStore(),
    copy: CopyStore(),
    dict: DictStore()
}

// i18n
const lang = app.config.globalProperties.db.settings.lang
const i18n = createI18n({locale: lang || "en", fallbackLocale: "en", messages: { en },});
app.use(i18n);

// meta
const head = createHead()
app.use(head)

// highlight
app.use(hljsVuePlugin)

//google
app.use(vue3GoogleLogin, {clientId: '380226631975-vfcmqh1fer3kgi18n6rj9nb21pfc7hjc.apps.googleusercontent.com'})



// upload
app.directive('upload', UploadDirective)
app.directive('color', ColorDirective)
app.directive('drop', DropDirective)
app.directive('dragover', DragOverDirective)

// api
app.config.globalProperties.api = api

// tools
app.config.globalProperties.tools = tools

// dev
app.config.globalProperties.dev = process.env.NODE_ENV == 'development'

if (process.env.NODE_ENV != 'development'){
    window.console.log = function(){ return}
    window.console.trace = function(){ return}
    window.console.warn = function(){ return}
    window.console.group = function(){ return}
    window.console.groupEnd = function(){ return}
}

app.config.globalProperties.humanize = humanize

// helpers
app.config.globalProperties.go = function(name: string) {router.push({name:name})}
app.config.globalProperties.is = function(name: string) {return router.currentRoute.value.name == name}
app.config.globalProperties.iss = function(name: string) {return router.currentRoute.value.name?.toString().startsWith(name)}
app.config.globalProperties.openlink = function(link: string) {window.open(link, '_blank');}
app.config.globalProperties.golink = function(link: Location) {window.location = link}
app.config.globalProperties.clipboard = async function(text: string) { await navigator.clipboard.writeText(text)},
app.config.globalProperties.boom = function(){ confetti({particleCount: 200,spread: 200,gravity:.9,scalar:3 }) }

 
// framework
app.component('Box', Box);
app.component('Gap', Gap);
app.component('Switch', Switch);
app.component('Checkbox', Checkbox);
app.component('Head', Head);
app.component('Footer', Footer);
app.component('Dark', Dark);
app.component('Down', Down);
app.component('Text', Text);
app.component('Input', Input);
app.component('Drop', Drop);
app.component('Error', Error);
app.component('Lang', Lang);
app.component('Color', Color);
app.component('ColorPreview', ColorPreview);
app.component('Login', Login);
app.component('Loader', Loader);
app.component('Download', Download);
app.component('CopyCardSmall', CopyCardSmall);
app.component('BlockTitle', BlockTitle);
app.component('Ago', Ago);
app.component('Hooks', Hooks);
app.component('Popup', Popup);
app.component('EmbedForm', EmbedForm);
app.component('Preview', Preview);
app.component('Onboarding', Onboarding);

// profile
app.component('Profile', Profile);

// admin
app.component('AdminAccounts', AdminAccounts);
app.component('AdminCopyCard', AdminCopyCard);
