import { ProfileStore } from '@/ts/store/profile';
import { SettingsStore } from '@/ts/store/settings';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const db = ProfileStore()
const profile: any = db.me
const logged: any = profile.id

const settings = SettingsStore()

// function lazyLoad(view){
//   return() => import(`@/views/${view}.vue`)
// }

const routes: Array<RouteRecordRaw> = [
  
  //general
  {path: '/',         name: 'home',     component: () => { return logged ? import('@/page/home.vue') : import('@/page/landing.vue')}},
  
  // profile
  {path: '/profile',  name: 'profile', beforeEnter: [user],  component: () => import('@/page/profile/index.vue'),
    children: [        
        {path: 'copy',  name: 'profile/copy', beforeEnter: [user], component: () => import('@/page/profile/copy.vue')},
        {path: 'download',  name: 'profile/download', beforeEnter: [user], component: () => import('@/page/profile/download.vue')},
        {path: 'support',  name: 'profile/support', beforeEnter: [user], component: () => import('@/page/profile/support.vue')},
        {path: 'settings',  name: 'profile/settings', beforeEnter: [user], component: () => import('@/page/profile/settings.vue')},
        {path: 'newsletters',  name: 'profile/newsletters', beforeEnter: [user], component: () => import('@/page/profile/newsletters.vue')},
        {path: 'stats',   name: 'profile/stats',  beforeEnter: [user], component: () => import('@/page/profile/index.vue')},
    ]
  }, 
  // profile
  {path: '/admin',  name: 'admin', beforeEnter: [user, admin],  component: () => import('@/page/admin/index.vue'),
    children: [        
        {path: 'copy',  name: 'admin/copy', beforeEnter: [user, admin], component: () => import('@/page/admin/copy.vue')},
        {path: 'accounts',  name: 'admin/accounts', beforeEnter: [user, admin], component: () => import('@/page/admin/accounts.vue')},
        {path: 'download',  name: 'admin/download', beforeEnter: [user, admin], component: () => import('@/page/admin/download.vue')},
        {path: 'support',  name: 'admin/support', beforeEnter: [user, admin], component: () => import('@/page/admin/support.vue')},
        {path: 'settings',  name: 'admin/settings', beforeEnter: [user, admin], component: () => import('@/page/admin/settings.vue')},
        {path: 'accounts',  name: 'admin/newsletters', beforeEnter: [user, admin], component: () => import('@/page/admin/newsletters.vue')},
        {path: 'stats',   name: 'admin/stats',  beforeEnter: [user, admin], component: () => import('@/page/admin/stats.vue')},
    ]
  }, 

  // create
  {path: '/create',   name: 'create',    beforeEnter: [user], component: () => import('@/page/create.vue')}, //beforeEnter: [user],  
  
  // services
  {path: '/services',         name: 'services',   component: () => import('@/page/services/index.vue')}, 
  {path: '/services/design',  name: 'design',     component: () => import('@/page/services/index.vue')}, 
  
  // info
  {path: '/about',      name: 'about',     component:  () => import('@/page/about/index.vue')}, 
  {path: '/company',    name: 'company',    component: () => import('@/page/about/index.vue')}, 
  {path: '/privacy',    name: 'privacy',    component: () => import('@/page/about/privacy.vue')},
  {path: '/agreement',  name: 'agreement',  component: () => import('@/page/about/index.vue')},
  {path: '/terms',      name: 'terms',      component: () => import('@/page/about/index.vue')},
  {path: '/sales',      name: 'sales',      component: () => import('@/page/about/index.vue')},
  {path: '/terms',      name: 'terms',      component: () => import('@/page/about/index.vue')},
  {path: '/cookie',     name: 'cookie',     component: () => import('@/page/about/index.vue')},
  {path: '/copyright',  name: 'copyright',  component: () => import('@/page/about/index.vue')},
  {path: '/ad',         name: 'ad',         component: () => import('@/page/about/index.vue')},
  {path: '/sitemap',    name: 'sitemap',    component: () => import('@/page/about/index.vue')},
  {path: '/help',       name: 'help',       component: () => import('@/page/about/index.vue')},  
  {path: '/faq',        name: 'faq',        component: () => import('@/page/about/index.vue')},
  {path: '/support',    name: 'support',    component: () => import('@/page/about/index.vue')},  
  {path: '/apps',       name: 'apps',       component: () => import('@/page/about/index.vue')},
  {path: '/subscribe',  name: 'subscribe',  component: () => import('@/page/about/index.vue')},
  

  // copy page
  // { path: '/images*', redirect:`${window.location}`},
  // { path: '/images*', beforeEnter:[images], component: () => import('@/page/about/index.vue')},

  {path: '/:id',   name: 'copy',     component: () => import('@/page/copy.vue')}, 

  
  
   // air
  //  {path: '/admin', name: 'admin',  beforeEnter: [admin], component: () => import('@/page/admin/index.vue'),
  //   children: [
  //       {path: 'users',   name: 'admin/users',    beforeEnter: [admin], component: () => import('@/page/admin/users.vue')},
  //       {path: 'credits', name: 'admin/credits',  beforeEnter: [admin], component: () => import('@/page/admin/credits.vue')},
  //       {path: 'logs',    name: 'admin/logs',     beforeEnter: [admin], component: () => import('@/page/admin/index.vue')},
  //   ]
  // },

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// GOOD
router.beforeEach((to, from, next) => {
  settings.resetPage()
  next()
})


function user(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (profile.id){
    next()
  } else{
    next({ name: 'home' })
  }
}


function images(to: any, from: any, next: any) {
    next(window.location)  
}

function pub(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (!profile.id){
    next()
  } else{
    next({ name: 'home'})
  }
}

function admin(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (profile.id && profile.admin){
    next()
  } else{
    next({ name: 'home'})
  }
}

function loggedNotInit(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (profile.id && !profile.name){
    next({ name: 'init' })
  } else{
    next()
  }
}

// init profile
function init(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (to.name=='init'){
    if (!profile.id){
      next({ name: 'welcome'})
      return
    }
    if (profile.id && profile.name){
      next({ name: 'profile'})
      return
    }
    if (profile.id && !profile.name){
      next()
      return
    }
  } else {
    if (!profile.id){
      next({ name: 'welcome'})
      return
    }
    if (profile.id && profile.name){
      next()
      return
    }
    if (profile.id && !profile.name){
      next({ name: 'init'})
      return
    }
  }
}

export default router
